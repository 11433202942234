import { lazy, Suspense, useMemo } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useMediaMatch } from 'rooks'
import { useAppManagementContext } from 'scenes/appManagement/context'

import AppSettingsLayout from './AppSettingsLayout'
import { AppMongoRouter } from './mongo'

const AppGeneralSettingsScene = lazy(() => import('./general'))
const AppAdminSettingsScene = lazy(() => import('./administrator'))
const AppSettingsSelectionScene = lazy(
  () => import('./AppSettingsSelectionScene'),
)

export default function AppSettingsRouter() {
  const { viewerCanAccessAdminSettings, viewerCanAccessMongo } =
    useAppManagementContext()
  const isMobile = useMediaMatch('(max-width: 768px)')
  const RootElement = useMemo(
    () =>
      isMobile ? (
        <AppSettingsSelectionScene />
      ) : (
        <Navigate to="general" replace />
      ),
    [isMobile],
  )

  return (
    <Suspense>
      <Routes>
        <Route path="/" element={<AppSettingsLayout />}>
          <Route path="" element={RootElement} />
          <Route path="general" element={<AppGeneralSettingsScene />} />
          {viewerCanAccessMongo && (
            <Route path="mongo/*" element={<AppMongoRouter />} />
          )}
          {viewerCanAccessAdminSettings && (
            <Route path="admin" element={<AppAdminSettingsScene />} />
          )}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </Suspense>
  )
}
