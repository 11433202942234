import { Container } from 'components/layout'
import { Suspense } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import AppSettingsNavigation from './AppSettingsNavigation'

export default function AppSettingsLayout() {
  const location = useLocation()

  const isConfiguring = location.pathname.endsWith('/settings/mongo/configure')

  return (
    <Container className="grid grid-cols-12 py-8">
      {!isConfiguring && <AppSettingsNavigation />}
      <Suspense>
        <Outlet />
      </Suspense>
    </Container>
  )
}
