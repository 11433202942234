/**
 * @generated SignedSource<<eb449dd24c4aa5ef6f8947e3adb62399>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AnnouncementSeverity = "DANGER" | "INFO" | "WARNING" | "%future added value";
export type AppManagementSceneQuery$variables = {
  appId: string;
};
export type AppManagementSceneQuery$data = {
  readonly viewer: {
    readonly appById: {
      readonly announcements: ReadonlyArray<{
        readonly details: string | null;
        readonly id: string;
        readonly severity: AnnouncementSeverity;
        readonly summary: string;
        readonly title: string;
      }>;
      readonly hydraCluster: {
        readonly isDedicated: boolean;
        readonly isValet: boolean;
      } | null;
      readonly transferRequestDestination: {
        readonly __typename: string;
      } | null;
      readonly viewerCanAccessMetrics: boolean;
      readonly viewerCanDelete: boolean;
      readonly viewerCanReadApiKey: boolean;
      readonly viewerCanReadAppData: boolean;
      readonly viewerCanReadLicense: boolean;
      readonly viewerCanReadMongoConnector: boolean;
      readonly viewerCanReadStreams: boolean;
      readonly viewerCanTransfer: boolean;
      readonly viewerCanUpdate: boolean;
      readonly viewerOwnsApp: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"AppHeader_app">;
    } | null;
  };
};
export type AppManagementSceneQuery = {
  response: AppManagementSceneQuery$data;
  variables: AppManagementSceneQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "appId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "appId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isDedicated",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isValet",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Announcement",
  "kind": "LinkedField",
  "name": "announcements",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "summary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "details",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "severity",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerCanAccessMetrics",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerCanReadLicense",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerCanReadApiKey",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerCanReadAppData",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerCanReadStreams",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerCanUpdate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerOwnsApp",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerCanDelete",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerCanTransfer",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerCanReadMongoConnector",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppManagementSceneQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "App",
            "kind": "LinkedField",
            "name": "appById",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AppHeader_app"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "HydraCluster",
                "kind": "LinkedField",
                "name": "hydraCluster",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "transferRequestDestination",
                "plural": false,
                "selections": [
                  (v16/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppManagementSceneQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "App",
            "kind": "LinkedField",
            "name": "appById",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  (v16/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v4/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/)
                    ],
                    "type": "Organization",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      }
                    ],
                    "type": "User",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "HydraCluster",
                "kind": "LinkedField",
                "name": "hydraCluster",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "transferRequestDestination",
                "plural": false,
                "selections": [
                  (v16/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "type": "Organization",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "919a1a13d88c10e2a1823e4f33913205",
    "id": null,
    "metadata": {},
    "name": "AppManagementSceneQuery",
    "operationKind": "query",
    "text": "query AppManagementSceneQuery(\n  $appId: ID!\n) {\n  viewer {\n    appById(id: $appId) {\n      ...AppHeader_app\n      hydraCluster {\n        isDedicated\n        isValet\n        id\n      }\n      announcements {\n        id\n        title\n        summary\n        details\n        severity\n      }\n      viewerCanAccessMetrics\n      viewerCanReadLicense\n      viewerCanReadApiKey\n      viewerCanReadAppData\n      viewerCanReadStreams\n      viewerCanUpdate\n      viewerOwnsApp\n      viewerCanDelete\n      viewerCanTransfer\n      viewerCanReadMongoConnector\n      transferRequestDestination {\n        __typename\n        ... on Organization {\n          id\n        }\n      }\n      id\n    }\n  }\n}\n\nfragment AppHeader_app on App {\n  id\n  name\n  slug\n  tenant {\n    __typename\n    ... on Organization {\n      id\n      name\n      slug\n      __typename\n    }\n    ... on User {\n      id\n      email\n      firstName\n      lastName\n      __typename\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cbfa3c0ef5612c06bb5203ee021d9f7f";

export default node;
