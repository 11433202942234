/**
 * @generated SignedSource<<23a90ff7d6484ee60919a002c798cae0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppMongoRouterQuery$variables = {
  appId: string;
};
export type AppMongoRouterQuery$data = {
  readonly viewer: {
    readonly appById: {
      readonly id: string;
      readonly mongoConnector: {
        readonly " $fragmentSpreads": FragmentRefs<"MongoConnectorDetails_link">;
      } | null;
      readonly viewerCanManageMongoConnector: boolean;
    } | null;
  };
};
export type AppMongoRouterQuery = {
  response: AppMongoRouterQuery$data;
  variables: AppMongoRouterQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "appId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "appId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerCanManageMongoConnector",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppMongoRouterQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "App",
            "kind": "LinkedField",
            "name": "appById",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MongoLink",
                "kind": "LinkedField",
                "name": "mongoConnector",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "MongoConnectorDetails_link"
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppMongoRouterQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "App",
            "kind": "LinkedField",
            "name": "appById",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MongoLink",
                "kind": "LinkedField",
                "name": "mongoConnector",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "connectionString",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "database",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MongoCollection",
                    "kind": "LinkedField",
                    "name": "collections",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "idMappingFields",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bb0ea2312476074ded9bb6d9462b9a59",
    "id": null,
    "metadata": {},
    "name": "AppMongoRouterQuery",
    "operationKind": "query",
    "text": "query AppMongoRouterQuery(\n  $appId: ID!\n) {\n  viewer {\n    appById(id: $appId) {\n      id\n      mongoConnector {\n        ...MongoConnectorDetails_link\n      }\n      viewerCanManageMongoConnector\n    }\n  }\n}\n\nfragment MongoConnectorDetails_link on MongoLink {\n  id\n  connectionString\n  database\n  collections {\n    name\n    idMappingFields\n  }\n}\n"
  }
};
})();

(node as any).hash = "bd9e466afd9ce80a0760881746634c5c";

export default node;
