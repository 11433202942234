import { icons, RawLink, Tab, Tabs, useMediaMatch } from '@portal/components'
import paths from 'paths'
import { useCallback, useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppManagementContext } from 'scenes/appManagement/context'
import { useAppURLParams } from 'scenes/appManagement/hooks'

const { ChevronLeft } = icons

export default function AppSettingsNavigation() {
  const { pathname } = useLocation()
  const slugs = pathname.split('/').filter(Boolean)
  const lastSlug = slugs[slugs.length - 1]
  const navigate = useNavigate()
  const params = useAppURLParams()
  const isMobile = useMediaMatch('(max-width: 768px)')
  const { viewerCanAccessAdminSettings, viewerCanAccessMongo } =
    useAppManagementContext()

  const items = useMemo(
    () => [
      {
        key: 'general',
        text: 'General',
        to: paths.appManagement({
          appSlug: params.appSlug,
          organizationSlug: params.organizationSlug || '',
          tenantId: params.userId,
          area: 'settings/general',
        }),
      },
      ...(viewerCanAccessMongo
        ? [
            {
              key: 'mongo',
              text: 'MongoDB Connector',
              to: paths.appManagement({
                appSlug: params.appSlug,
                organizationSlug: params.organizationSlug || '',
                tenantId: params.userId,
                area: 'settings/mongo',
              }),
            },
          ]
        : []),
      ...(viewerCanAccessAdminSettings
        ? [
            {
              key: 'admin',
              text: 'Administrator',
              to: paths.appManagement({
                appSlug: params.appSlug,
                organizationSlug: params.organizationSlug || '',
                tenantId: params.userId,
                area: 'settings/admin',
              }),
            },
          ]
        : []),
    ],
    [params, viewerCanAccessAdminSettings, viewerCanAccessMongo],
  )

  const selected = items.find((item) => item.key === lastSlug)

  useEffect(() => {
    if (!selected && items.length > 0 && !isMobile) {
      navigate(items[0].to, { replace: true })
    }
  }, [selected, navigate, items, isMobile])

  const handleChange = useCallback(
    (id: string) => {
      const navigateToItem = items.find(({ key }) => key === id)
      if (navigateToItem?.to) {
        navigate(navigateToItem.to)
      }
    },
    [navigate, items],
  )

  if (!selected) {
    return null
  }

  if (isMobile) {
    return (
      <div className="col-span-12 pt-1">
        <RawLink
          to={paths.appManagement({
            appSlug: params.appSlug,
            organizationSlug: params.organizationSlug || '',
            tenantId: params.userId,
            area: 'settings',
          })}
          className="flex h-12 w-full items-center text-base text-black outline-none focus-visible:outline-2 focus-visible:outline-offset-[-2px] focus-visible:outline-focus-outline dark:text-white"
        >
          <ChevronLeft className="mr-2 h-5 w-5" />
          {selected.text}
        </RawLink>
      </div>
    )
  }

  return (
    <div className="col-span-5 h-full shrink-0 pt-5">
      <Tabs
        selected={selected.key}
        onChange={handleChange}
        orientation="vertical"
      >
        {items.map(({ text, key }) => (
          <Tab id={key} key={key} title={text}>
            {null}
          </Tab>
        ))}
      </Tabs>
    </div>
  )
}
