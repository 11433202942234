import { PropsWithChildren } from 'react'

import { useDittoAppConnection } from '../../components/dittoAppConnection'
import AppSettingsContext, { AppManagementContextProps } from './context'

type Props = PropsWithChildren<
  {
    appId: string
  } & Omit<AppManagementContextProps, 'cloudDomainUrl' | 'error'>
>

/** Context provider for the app management area. */
const AppManagementContextProvider = ({
  appId,
  children,
  isDomainReady,
  ...ctx
}: Props) => {
  const { error, cloudDomain } = useDittoAppConnection(appId, isDomainReady)

  return (
    <AppSettingsContext.Provider
      value={{
        error,
        appId,
        isDomainReady,
        cloudDomainUrl: cloudDomain?.url,
        ...ctx,
      }}
    >
      {children}
    </AppSettingsContext.Provider>
  )
}

export default AppManagementContextProvider
